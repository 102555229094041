import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import GetStarted from "../components/sections/GetStarted";

import hero from "../images/google-pay-hero.png";
import section1 from "../images/Illustration-Google-1.png";
import section2 from "../images/Illustration-Google-2.png";
import card1 from "../images/section-img-1.png";
import card2 from "../images/section-img-2.png";
import { Link } from "gatsby";

const GooglePay = () => {
  return (
    <Layout hiddenContactBtn>
      <SEO
        title="Cards - Digimeth.net"
        description="Make the payment flow pleasant with PSP Digimeth. Integrate Google pay and allow your customers to pay in one click."
      />
      <article>
        <section
          className="typical__padding"
          style={{ paddingTop: `40px`, paddingBottom: `120px` }}
        >
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content" style={{ maxWidth: `600px` }}>
                  <h2 style={{ marginBottom: `20px` }}>
                    <span className="default">
                      Enjoy simple and secure payments with
                    </span>
                    <span className="colorized"> Google Pay</span>
                  </h2>
                  <h3 className="description">
                    Google Pay™ is an instant payment method from Google. It
                    enables users to pay in one click. Moreover the card data is
                    safely stored by Google.
                  </h3>
                  <br />
                  <small
                    style={{
                      maxWidth: `530px`,
                      width: `100%`,
                      display: `inline-block`,
                    }}
                  >
                    Enjoy Google Pay payment experience in different web
                    browsers or with any Android device
                  </small>
                </div>
              </div>
            </div>
            <div className="right">
              <picture className="fluid__wrapper">
                <img alt="hero" src={hero} />
              </picture>
            </div>
          </div>
        </section>
        <section className="alternative_payment-section typical__padding co-bg--white">
          <div className="site__container">
            <div className="customer-container">
              <div className="customer-container-card">
                <div className="content">
                  <div className="side left">
                    <div className="customer-cover">
                      <div className="content-wrapper-box">
                        <p>How does it work?</p>
                        <h3>
                          Customer either already has or adds a credit or debit
                          card to the Google Wallet™ app.
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="side right">
                    <div className="customer-cover">
                      <div className="content-wrapper-box">
                        <picture>
                          <img src={section1} alt="connect" />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="customer-container-card">
                <div className="content">
                  <div className="side left">
                    <div className="customer-cover">
                      <div className="content-wrapper-box">
                        <h3>
                          Сustomer taps the Google Pay button and makes a
                          transaction.
                        </h3>
                        <p>
                          That’s it – no need to fill out forms or enter payment
                          info!
                        </p>
                        <Link
                          to="/contact"
                          style={{ marginTop: `20px`, display: `inline-block` }}
                        >
                          <button
                            type="button"
                            className="digimeth-btn default-btn xl"
                          >
                            Start integration
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="side right">
                    <div className="customer-cover">
                      <div className="content-wrapper-box">
                        <picture>
                          <img src={section2} alt="connect" />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="secondary_payment-section typical__padding co-bg--grey mt-100">
          <div className="site__container " style={{paddingTop: `80px`}}>
            <h2 className="secodary-title">
              How to integrate Google Pay to your website
            </h2>
            <p
              className="secondary-description"
              style={{ maxWidth: `530px`, width: `100%`, margin: `0 auto` }}
            >
              Google Pay can be integrated directly via Google Pay API or
              through the Digimeth payment page
            </p>
            <div className="connection-container">
              <div className="connection-container-card">
                <div className="content">
                  <div className="top">
                    <h3 className="title">Connection with Google Pay API</h3>
                    <p className="description">
                      You can place the Google Pay button directly on your
                      website. Your website should use an HTTPS connection and
                      support TLS 1.2. You need to perform additional steps to
                      get started with the Google Pay API. Please find more
                      information and the instructions via the
                      <a
                        href="https://developers.google.com/pay/api/web/overview"
                        target="_blank"
                        style={{ textDecoration: `none`, color: `#437873` }}
                        rel="noreferrer"
                      >
                        {" "}
                        link
                      </a>
                      .
                    </p>
                  </div>
                  <picture>
                    <img src={card1} alt="connection" />
                  </picture>
                </div>
              </div>
              <div className="connection-container-card">
                <div className="content">
                  <div className="top">
                    <h3 className="title">
                      Connection via the Digimeth payment page
                    </h3>
                    <p className="description">
                      All you need is an integration with Digimeth - there is no
                      need for additional integrations. The Google Pay button
                      will be on the Digimeth payment page. The technical
                      integration is also the same as for other payment methods
                      used through the payment page
                    </p>
                  </div>
                  <picture>
                    <img src={card2} alt="connection"/>
                  </picture>
                </div>
              </div>
            </div>
            <div className="useful-links-containder">
              <h3 className="useful-links-title">Useful links</h3>
              <ul className="useful-links-list">
                <li>
                  <a
                    href="https://developers.google.com/pay/api/web/guides/brand-guidelines"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Brand guidelines for websites
                  </a>
                </li>
                <li>
                  <a
                    href="https://payments.developers.google.com/terms/aup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Pay and Wallet APIs Acceptable Use Policy
                  </a>
                </li>
              </ul>
              <p>Google Pay and Google Wallet are trademarks of Google LLC</p>
            </div>
          </div>
        </section>
        <GetStarted
          isGetStarted={true}
          title="Simple and secure payments with Google Pay"
        />
        </article>
    </Layout>
  );
};

export default GooglePay;
